export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'product-category',
    rules: '',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'newsCategoryId',
    label: 'field.category',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'newsCategory',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    skip: true,
    hide: true,
    cols: 12,
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'descriptionKm',
    label: 'field.descriptionKm',
    type: 'textarea',
  },
  {
    key: 'descriptionEn',
    label: 'field.descriptionEn',
    type: 'textarea',
  },
  {
    key: 'type',
    label: 'field.type',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'newsType.1', value: '1' },
      { text: 'newsType.2', value: '2' },
    ],
    cols: "auto",
  },
  {
    skip: true,
    hide: true,
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'attachments',
    label: 'field.attachments',
    type: 'document-uploader',
    cols: 12,
  },
]
